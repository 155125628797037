import React, { Suspense, useEffect, useState } from "react";
import { Box, useMediaQuery } from "@mui/material";
import PropTypes from "prop-types";
import SuspanceLoading from "./SuspanceLoading";
import Header from "./Homepage/Header";
// import Footer from "./Homepage/Footer";
import { useLocation } from "react-router-dom";
import SideNavBar from "./LawyerCompo/Dashboard/SideNavBar";
import Navbar from "./LawyerCompo/Dashboard/Navbar";

const Layout = ({ children }) => {
  const location = useLocation();
  const [renderContent, setRenderContent] = useState(null);
  const [openSide, setOpenSide] = useState(false);

  const handleDrawerOpen = () => {
    console.log("clicked");
    setOpenSide(!openSide);
  };

  const xs = useMediaQuery("(max-width: 600px)");
  const sm = useMediaQuery("(min-width: 601px) and (max-width: 900px)");

  const urlPath = location.pathname;

  const renderLayout = () => {
    // 1. Homepage/Landing Layout
    if (urlPath === "/") {
      return (
        <Box>
          <Header />
          <Box>{children}</Box>
          {/* <Footer /> */}
        </Box>
      );
    }
    // 2. Client web Layout
    else if (urlPath.startsWith("/Client")) {
      return (
        <Box sx={{ display: "flex" }}>
          <SideNavBar />
          <Box sx={{ width: "100%" }}>
            <Navbar />
            <Box sx={{ padding: "20px" }}>{children}</Box>
          </Box>
        </Box>
      );
    }
    // 3. Lawyer Web Layout
    else if (urlPath.startsWith("/dashboard")) {
      return (
        <Box>
          <Box sx={{ width: "100%" }}>
            <Navbar handleDrawerOpen={handleDrawerOpen} />
            <Box sx={{ display: "flex", width: "100%" }}>
              <SideNavBar openSide={openSide} />
              <Box
                sx={{
                  p: "1rem",
                  width: "100%",
                }}
              >
                {children}
              </Box>
            </Box>
          </Box>
        </Box>
      );
    }
    // Default - Render just the main content if no matching route
    return <Box>{children}</Box>;
  };

  useEffect(() => {
    setRenderContent(renderLayout());
  }, [urlPath, children]);

  return <Suspense fallback={<SuspanceLoading />}>{renderLayout()}</Suspense>;
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
