import React from "react";
import { Box, Typography } from "@mui/material";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { DashboardSideMenu } from "../../../static/staticdata";

const SideNavBar = ({ openSide }) => {
  const location = useLocation();
  const navigate = useNavigate();

  return (
    <>
      <Box
        sx={{
          height: "100vh",
          px: openSide ? "0.5rem" : "1rem",
          py: "1rem",
          position: "relative",
          bgcolor: "#001638",
          transition: "all 0.3s ease",
          width: openSide ? "6rem" : "16rem",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: "0.5rem",
            overflowY: "auto",
            height: "80%",
            p: "0.5rem",
            "&::-webkit-scrollbar": { width: "0.4rem" },
            "&::-webkit-scrollbar-track": {
              // border: "1px solid #e5e5e5",
              borderRadius: "10px",
            },
            "&::-webkit-scrollbar-thumb": {
              background: "#524dff",
              borderRadius: "10px",
            },
          }}
        >
          {DashboardSideMenu?.map((item, i) => {
            const isActive = location.pathname === item?.path;
            return (
              <NavLink
                key={i}
                to={item?.path}
                style={{
                  display: "flex",
                  justifyContent: "start",
                  alignItems: "center",
                  textDecoration: "none",
                  backgroundColor: isActive ? "#0082b7" : "transparent",
                  color: isActive ? "#ffff" : "#ffff",
                  width: openSide ? "3rem" : "100%",
                  minHeight: "2.5rem",
                  borderRadius: "0.625rem",
                  overflow: "hidden",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: "0.5rem",
                    px: "0.5rem",
                    pl: "1rem",
                  }}
                >
                  {item?.mIcon}
                  <Typography
                    variant="body1"
                    sx={{
                      fontSize: "1rem",
                      fontWeight: isActive ? 500 : 400,
                      opacity: openSide ? 0 : 1,
                      transition: "all 0.3s ease",
                    }}
                  >
                    {item?.mText}
                  </Typography>
                </Box>
              </NavLink>
            );
          })}
        </Box>
      </Box>
    </>
  );
};

export default SideNavBar;
