import React, { useState } from 'react';
import { TextField, Button } from '@mui/material';
import { motion } from 'framer-motion';
import videoSrc from "../../assets/video.gif";
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setToken, setUser } from '../../redux/users/userSlice';
import { backend_api } from '../../static/server';

const SignupPage = () => {
  const [email, setEmail] = useState('');
  const [error, setError] = useState('')
  const [password, setPassword] = useState('');
  const [otp, setOtp] = useState('');
  const [encryptedKey, setEncryptedKey] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleLogin = async () => {
    setError("")
    try {
      const body = new URLSearchParams();
      body.append('email', email);
      body.append('password', password);

      const response = await axios.post(
        `${backend_api}lawer/login/`,
        body,
        {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        }
      );
      console.log("responseLexmoom", response.data.data)

      if (response.data.data.user) {
        dispatch(setToken(response.data.data.user.token));
        dispatch(setUser(response.data.data.user));
        navigate('/dashboard');
        localStorage.setItem(
          "UserId",
          JSON.stringify({
            token: response.data.data.user.token,
            emailId: response.data.data.user.email
          })
        );
      } else if (response.data.data.encrypt_key) {
        console.log("zzz")
        setEncryptedKey(response.data.data.encrypt_key);
      }
    } catch (error) {
      setError(error.response.data.message)
      console.error('Login error', error);
    }
  };

  const handleOtpVerify = async () => {
    setError("")
    try {
      const body = new URLSearchParams();
      body.append('email', email);
      body.append('otp', otp);
      body.append('encrypted_key', encryptedKey);

      const response = await axios.post(
        `${backend_api}lawer/otp-verify`,
        body,
        {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        }
      );
      console.log(response, "otpverify")

      if (response.data.data.user) {
        console.log("response.data.user", response.data.data.user)
        dispatch(setToken(response.data.data.user.token));
        dispatch(setUser(response.data.data.user));
        navigate('/dashboard');
        localStorage.setItem(
          "UserId",
          JSON.stringify({
            token: response.data.data.user.token,
            emailId: response.data.data.user.email
          })
        );
      }
    } catch (error) {
      setError(error.response.data.message)
      console.error('OTP Verification Error', error);
    }
  };

  return (
    <div className="min-h-screen flex flex-col-reverse 800px:flex-row overflow-hidden">
      {/* Left Side: Form Section */}
      <motion.div
        initial={{ opacity: 0, x: -100 }}
        animate={{ opacity: 1, x: 0 }}
        transition={{ duration: 0.8 }}
        className="w-full 800px:w-1/2 bg-white flex flex-col justify-center items-center p-8"
      >

        <h2 className="text-3xl font-semibold mb-6">
          Login
        </h2>

        <form className="space-y-4 w-full max-w-sm">

          <TextField fullWidth label="Email" type="email" variant="outlined" value={email}
            onChange={(e) => setEmail(e.target.value)} />
          <TextField
            fullWidth
            label="Password"
            type="password"
            variant="outlined" value={password}
            onChange={(e) => setPassword(e.target.value)}
          />


          {encryptedKey ? (
            <>
              <TextField
                label="OTP"
                value={otp}
                onChange={(e) => setOtp(e.target.value)}
                fullWidth
                margin="normal"
              />
              <p className='text-red-700'>{error}</p>
              <Button onClick={handleOtpVerify} variant="contained" color="primary">
                Verify OTP
              </Button>
            </>
          ) : <>
            <p className='text-red-700'>{error}</p>
            <Button
              variant="contained"
              color="primary"
              className="w-full mt-4 py-2"
              onClick={handleLogin}
            >
              Login
            </Button></>}


        </form>


      </motion.div>

      {/* Right Side: Video/Photo Section */}
      <motion.div
        initial={{ opacity: 0, x: 100 }}
        animate={{ opacity: 1, x: 0 }}
        transition={{ duration: 0.8 }}
        className="w-full 800px:w-1/2 relative bg-gray-900 text-white flex items-center justify-center flex-col p-10"
      >
        {/* Video Background */}
        <img src={videoSrc} alt="Background animation" className="w-full" />

        {/* Overlay Information */}
        <div className="relative z-10 text-center mt-4">
          <h2 className="text-4xl font-bold">Welcome to Our Platform</h2>
          <p className="mt-4 text-lg">
            Join us and enjoy exclusive features designed just for you!
          </p>
        </div>
      </motion.div>
    </div>
  );
};

export default SignupPage;
