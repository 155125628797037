import { Box, Button, TextField } from "@mui/material";
import React from "react";
import styles, { pageScrollStyle } from "../styles/styles";
import DataGridCompon from "./DataGridCompon";

const columns = [
  { field: "Name", headerName: "Name", minWidth: 150, flex: 1.2 },
  { field: "Role", headerName: "Role", minWidth: 80, flex: 0.8 },
  { field: "LoginType", headerName: "Login Type", minWidth: 100, flex: 1 },
  { field: "Phone", headerName: "Phone", minWidth: 120, flex: 0.7 },
  { field: "Email", headerName: "Email", minWidth: 150, flex: 1.5 },
  { field: "Mobile", headerName: "Mobile", minWidth: 120, flex: 0.7 },
];

const rows = [
  { id: 1, Name: "John Doe", Type: "Admin", Default: "Yes", Required: "No" },
  { id: 2, Name: "Jane Smith", Type: "User", Default: "No", Required: "Yes" },
  {
    id: 3,
    Name: "Alex Johnson",
    Type: "Editor",
    Default: "Yes",
    Required: "No",
  },
  {
    id: 3,
    Name: "Alex Johnson",
    Type: "Editor",
    Default: "Yes",
    Required: "No",
  },
];

const CustomeFields = () => {
  return (
    <>
      <div className="w-full">
        <Box sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
          <div className="flex justify-between bg-white rounded-lg items-center shadow-md p-3">
            <h1 className={`${styles.title}`}> Custom Fields</h1>
            <Box className="flex gap-4">
              <Button variant="outlined" sx={{ textTransform: "capitalize" }}>
                Modify Order
              </Button>
              <Button variant="contained" sx={{ textTransform: "capitalize" }}>
                Add field
              </Button>
            </Box>
          </div>

          <Box
            className=" bg-white rounded-lg justify-between h-[80vh] p-4 w-full border-2"
            sx={{
              ...pageScrollStyle,
              overflow: "auto",
            }}
          >
            <Box sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
              <Box sx={{ display: "flex" }}>
                <TextField
                  size="small"
                  placeholder="Search task"
                  className="flex-1"
                  label="Search"
                  sx={{ width: "15rem" }}
                />
              </Box>

              <Box>
                <DataGridCompon columns={columns} rows={rows} />
              </Box>
            </Box>
          </Box>
        </Box>
      </div>
    </>
  );
};

export default CustomeFields;
