import React, { Suspense } from "react";
import "./App.css";
import Layout from "./components/Layout";
import SuspanceLoading from "./components/SuspanceLoading";
import ProtectedRoutes from "./components/ProtectedRoutes";

function App() {
  return (
    <Layout>
      <Suspense fallback={<SuspanceLoading />}>
        <ProtectedRoutes />
      </Suspense>
    </Layout>
  );
}

export default App;
