import { Box } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";

const Hey = () => {
  return <Box>here we are</Box>;
};

const DataGridCompon = ({ rows = [], columns }) => {
  return (
    <Box>
      <DataGrid
        slots={{
          toolbar: GridToolbar,
        }}
        loading={rows.length > 0 ? false : true}
        slotProps={{
          loadingOverlay: {
            variant: "skeleton",
            noRowsVariant: "skeleton",
          },
        }}
        initialState={{
          pinnedColumns: {
            left: ["desk"],
          },
        }}
        localeText={{ noRowsLabel: <Hey /> }}
        rows={rows}
        columns={columns}
        pageSize={10}
        autoHeight
        disableSelectionOnClick
        sx={{
          ".MuiDataGrid-cell": {
            display: "flex",
            justifyContent: "start",
            alignItems: "center",
          },
          "& .MuiDataGrid-columnHeader": {
            backgroundColor: "#bdbdbd",
          },
          "& .MuiDataGrid-columnSeparator": {
            display: "none",
          },
        }}
      />
    </Box>
  );
};

export default DataGridCompon;
