import { SiFiles } from "react-icons/si";
import { RiContactsBook3Fill } from "react-icons/ri";
import { GoClockFill } from "react-icons/go";
import { FaMoneyBillTransfer } from "react-icons/fa6";
import { MdAccountBalance } from "react-icons/md";
import { IoDocuments } from "react-icons/io5";
import { FaComments } from "react-icons/fa";
import { IoMdAnalytics } from "react-icons/io";
import { RiAppsLine } from "react-icons/ri";
import { FaCalendarDays } from "react-icons/fa6";
import { IoSettings } from "react-icons/io5";
import { IoHome } from "react-icons/io5";
import { BsListTask } from "react-icons/bs";
import { BiSolidCustomize } from "react-icons/bi";

export const userData = JSON.parse(localStorage.getItem("UserId"));

export const menuItems = {
  products: [
    { title: "Product Overview", link: "/product1" },
    { title: "Prosecution Manager", link: "/product2" },
    { title: "Lexmom Plus", link: "/product3" },
    { title: "Invention Manager", link: "/product3" },
    { title: "Pricing", link: "/product3" },
    { title: "Free Trial", link: "/product3" },
  ],

  company: [
    { title: "About Us", link: "/about-us" },
    { title: "Why Lexmom", link: "/careers" },
    { title: "Career", link: "/investors" },
    { title: "Blog", link: "/investors" },
    { title: "Forum", link: "/investors" },
    { title: "Testimonials", link: "/investors" },
    { title: "Learning Center", link: "/investors" },
  ],
};

export const validateEmail = (email) => {
  const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return regex.test(email);
};
export const roles = [
  { value: "0", label: "" },
  { value: "1", label: "Account Administrator" },
  { value: "2", label: "Account Administrator (backup)" },
  { value: "3", label: "Assignee" },
  { value: "4", label: "Associate" },
  { value: "5", label: "Attorney" },
  { value: "6", label: "Client" },
  { value: "7", label: "Client/Solo Inventor" },
];

export const DashboardSideMenu = [
  { mIcon: <IoHome />, mText: "Dashboard", path: "/dashboard" },
  
  {
    mIcon: <FaCalendarDays />,
    mText: "Calendar",
    path: "/dashboard/calendars",
  },
  { mIcon: <BsListTask />, mText: "Tasks", path: "/dashboard/tasks" },
  { mIcon: <SiFiles />, mText: "Cases", path: "/dashboard/cases" },
  {
    mIcon: <RiContactsBook3Fill />,
    mText: "Contacts",
    path: "/dashboard/contact-details",
  },
  {
    mIcon: <GoClockFill />,
    mText: "Activities",
    path: "/dashboard/activities",
  },
  {
    mIcon: <FaMoneyBillTransfer />,
    mText: "Billing",
    path: "/dashboard/bills",
  },
  {
    mIcon: <MdAccountBalance />,
    mText: "Accounts",
    path: "/dashboard/bank_accounts",
  },
  { mIcon: <IoDocuments />, mText: "Doccuments", path: "/dashboard/documents" },
  {
    mIcon: <FaComments />,
    mText: "Communications",
    path: "/dashboard/communications",
  },
  { mIcon: <IoMdAnalytics />, mText: "Reports", path: "/dashboard/reports" },
  {
    mIcon: <RiAppsLine />,
    mText: "App Integrations",
    path: "/dashboard/app_integrations",
  },
  { mIcon: <BiSolidCustomize />, mText: "Custom Fields", path: "/dashboard/custom_fields" },
  { mIcon: <IoSettings />, mText: "Settings", path: "/dashboard/settings" },
];

export const personalDashBillData = [
  { title: "Draft Bills", num: 0, btnText: "create new bill" },
  { title: "Total in Draft ", num: 0 },
  { title: "Unpaid Bills", num: 0 },
  { title: "Total in Unpaid ", num: 0 },
  { title: "Overdue Bills", num: 0 },
  { title: "Total in Overdue", num: 0 },
];

export const personalDashOtherData = [
  { title: "Total Client", DataCout: 0 },
  { title: "Total Contacts", DataCout: 0 },
  { title: "Total Cases", DataCout: 0 },
  { title: "Pending Cases", DataCout: 0 },
];
