import { createTheme } from "@mui/material";

const styles = {
  custom_container: "w-11/12 hidden sm:block",
  heading:
    "text-[27px] text-center md:text-start font-[600] font-Roboto pb-[20px]",
  section: "w-11/12 mx-auto",
  productTitle: "text-[25px] font-[600] font-Roboto text-[#333]",
  productDiscountPrice: "font-bold text-[18px] text-[#333] font-Roboto",
  price: "font-[500] text-[16px] text-[#d55b45] pl-3 mt-[-4px] line-through",
  shop_name: "pt-3 text-[15px] text-blue-400 pb-3",
  active_indicator: "absolute bottom-[-27%] left-0 h-[3px] w-full bg-[crimson]",
  button:
    "w-[150px] bg-black h-[50px] my-3 flex items-center justify-center rounded-xl cursor-pointer",
  cart_button:
    "px-[20px] h-[38px] rounded-[20px] bg-[#f63b60] flex items-center justify-center cursor-pointer",
  cart_button_text: "text-[#fff] text-[16px] font-[600]",
  input: "w-full border p-1 rounded-[5px]",
  activeStatus:
    "w-[10px] h-[10px] rounded-full absolute top-0 right-1 bg-[#40d132]",
  noramlFlex: "flex items-center",
  border_button:
    "border-2 border-[#3957db] text-[#3957db] px-4 py-2 rounded-lg shadow",
  title: "text-[25px] font-[600] font-Roboto text-[#333]",
};
// #3957db blue
export default styles;

export const pageScrollStyle = {
  "&::-webkit-scrollbar": { width: "0.4rem" },
  "&::-webkit-scrollbar-track": {
    border: "1px solid #e5e5e5",
    borderRadius: "10px",
  },
  "&::-webkit-scrollbar-thumb": {
    background: "#524dff",
    borderRadius: "10px",
  },
};

export const lebaleStyle = {
  fontSize: "0.9rem",
  fontWeight: 500,
};

export const customTheme = createTheme({
  palette: {
    primary: {
      main: "#3957db", // Put Your Primary color of website
    },
    secondary: {
      main: "#db3a3e", // Put Your Secondary color of website
    },
    primaryText: {
      main: "#1C1B1F", // Put Your Primary text color of website
    },
    secondaryText: {
      main: "#888", // Put Your Secondary text color of website
    },
    success: {
      main: "#198754", // Gren
    },
    danger: {
      main: "#dc3545", // Red
    },
    warning: {
      main: "#ffc107", // Mustered Yellow
    },
    info: {
      main: "#0dcaf0", // Indigo Color
    },
    light: {
      main: "#f8f9fa", // light Gray Color
    },
    black: {
      main: "#000000", // Black
    },
    white: {
      main: "#FFFFFF", // White
    },
  },
  typography: {
    fontFamily: ["Poppins", "sans-serif"].join(","),
  },
});

// // FORMAT NUMBERS WITH COMMAS LIKE (27,444)
// export const formatNumberWithCommas = (number) => {
//   return number.toLocaleString();
// };

// // PRICE FUNCTION
// export const MrPrice = (Price) => {
//   return Price?.toLocaleString("en-US", {
//     style: "currency",
//     currency: "INR",
//   });
// };

// // GREETING FUNCTION
// export const getGreeting = () => {
//   const currentTime = new Date().toLocaleTimeString("en-US", {
//     timeZone: "Asia/Kolkata",
//   });
//   const hour = parseInt(currentTime.split(":")[0]);

//   if (hour >= 4 && hour < 12) {
//     return "Good morning!";
//   } else if (hour >= 12 && hour < 17) {
//     return "Good afternoon!";
//   } else {
//     return "Good evening!";
//   }
// };

// // USER STATUS (2 MONTH'S AGO) FUNCTION
// export const timeAgo = (timestamp) => {
//   const secondsAgo = Math.floor((Date.now() - timestamp) / 1000);

//   const intervals = {
//     year: 31536000,
//     month: 2592000,
//     day: 86400,
//     hour: 3600,
//     minute: 60,
//   };

//   for (let interval in intervals) {
//     const value = Math.floor(secondsAgo / intervals[interval]);
//     if (value >= 1) {
//       return value + " " + (value === 1 ? interval : interval + "s") + " ago";
//     }
//   }

//   return "Just now";
// };

// // date formate log date
export const formattedDate = (date) => {
  return new Date(date).toLocaleString("en-US", {
    month: "short",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
  });
};

// //phonenumber formatt
// export const formatPhoneNumber = (phoneNumber) => {
//   const numericOnly = phoneNumber.replace(/\D/g, "");
//   const formattedNumber = `+${numericOnly.slice(0, 2)} ${numericOnly.slice(
//     2,
//     7
//   )} ${numericOnly.slice(7)}`;

//   return formattedNumber;
// };

// // Get First character of each word
export const getFirstCharacters = (name) => {
  if (!name) return null; // Handling null or undefined cases
  return name
    .split(" ")
    .map((word) => word.charAt(0))
    .join("")
    .toUpperCase();
};

export const calculatePresent = (totalnum, num) => {
  return Math.round((num / totalnum) * 100);
};
