import React from 'react'
import { Box, Typography, Button } from '@mui/material'
import { IoCalendar } from "react-icons/io5";

const HomePage = () => {
  return (
    <>
      <Box sx={{ height: "80vh", position: "relative", display: "flex", justifyContent: "flex-start", alignItems: "center" }}>
        <Box sx={{ zIndex: -1, position: "absolute", top: 0, left: 0, height: "100%", overflow: "hidden" }}>
          <video src="https://www.appcoll.com/wp-content/uploads/2022/12/header-appcoll.mp4" alt="Background animation" className="w-full object-contain z-10" autoPlay loop muted />
        </Box>
        <Box sx={{ zIndex: 1, p: "1rem", display: "flex", flexDirection: "column ", gap: "1rem" }}>
          <Typography variant="h1" sx={{ color: "white.main", fontSize: "2.625rem", fontWeight: "400" }}>Intellectual Property Optimized</Typography>
          <Typography variant="h2" sx={{ color: "white.main", fontSize: "1.625rem", }}>No Downloads. No Software to Install</Typography>
          <Box >
            <Button variant="contained" color="primary" startIcon={<IoCalendar />} sx={{ ml: "6rem", textTransform: "capitalize", fontSize: "1rem" }}>
              30 day trial
            </Button>
          </Box>
        </Box>
      </Box>
    </>
  )
}

export default HomePage