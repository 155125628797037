import React from 'react'
import Signup from '../components/Auth/Signup'
const SignupPage = () => {
  return (
    <>
     <Signup/>
    </>
  )
}

export default SignupPage