import React, { useState } from 'react';
import { Button, MenuItem, Typography } from '@mui/material';
import { FaChevronDown } from 'react-icons/fa';
import { menuItems } from '../../static/staticdata';
import { IoCalendarOutline } from "react-icons/io5";
import { IoLogInOutline } from "react-icons/io5";
import { Link, useNavigate } from 'react-router-dom';

const Header = () => {
  const navigate = useNavigate()
  const [openMenu, setOpenMenu] = useState({ products: false, company: false });
  const handleMouseEnter = (menu) => setOpenMenu((prev) => ({ ...prev, [menu]: true }));
  const handleMouseLeave = (menu) => setOpenMenu((prev) => ({ ...prev, [menu]: false }));

  return (
    <nav className="w-full bg-[#3957db] p-4">
      <div className="container mx-auto flex items-center justify-between">
        <h1 className="text-white font-bold text-lg cursor-pointer" onClick={() => { navigate("/") }}>Lexmom</h1>

        {/* Dropdown Menus */}
        <div className="flex gap-6">


          {/* Products Dropdown */}
          <div
            className="relative"
            onMouseEnter={() => handleMouseEnter('products')}
            onMouseLeave={() => handleMouseLeave('products')}
          >
            <button className="flex p-2 rounded-md items-center text-white text-base font-medium hover:bg-blue-600" endIcon={<FaChevronDown />}>
              Products <FaChevronDown className='ml-2' />
            </button>
            <div
              className={`absolute mt-1  w-48 rounded-sm shadow-lg bg-white ring-1 ring-black ring-opacity-5 transform transition-all duration-300 ease-in-out ${openMenu.products ? 'opacity-100 scale-100' : 'opacity-0 scale-95 pointer-events-none'
                } left-1/2 -translate-x-1/2`}
            >
              {menuItems.products.map((item) => (
                <MenuItem key={item.title}>
                  <a href={item.link}>
                    <Typography variant="body2" className="text-gray-700">{item.title}</Typography>
                  </a>
                </MenuItem>
              ))}
            </div>
          </div>

          {/* Company Dropdown */}
          <div
            className="relative"
            onMouseEnter={() => handleMouseEnter('company')}
            onMouseLeave={() => handleMouseLeave('company')}
          >
            <button className="flex p-2 rounded-md items-center text-white text-base font-medium hover:bg-blue-600">
              Company
              <FaChevronDown className='ml-2' />
            </button>
            <div
              className={`absolute mt-1  w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 transform transition-all duration-300 ease-in-out ${openMenu.company ? 'opacity-100 scale-100' : 'opacity-0 scale-95 pointer-events-none'
                } left-1/2 -translate-x-1/2`}
            >
              {menuItems.company.map((item) => (
                <MenuItem key={item.title}>
                  <a href={item.link}>
                    <Typography variant="body2" className="text-gray-700">{item.title}</Typography>
                  </a>
                </MenuItem>
              ))}
            </div>
          </div>


          <button className='bg-[#00d084] flex p-2 rounded-md items-center text-white font-medium'>Demo <IoCalendarOutline className='ml-2 font-medium text-white' /></button>
          <Button onClick={() => navigate("/login")} variant="outlined" sx={{ borderColor: "white.main", color: "white.main", textTransform: "capitalize" }} endIcon={<IoLogInOutline />}>Log in</Button>
        </div>
      </div>
    </nav>
  );
};

export default Header;
