import { lazy } from "react";
import CustomeFields from "./components/CustomeFields";

const HomePage = lazy(() => import("./pages/LandingPages/HomePage"));
const SignupPage = lazy(() => import("./pages/SignupPage"));

// <-------------------------------lawyer pages all routes import here ---------------------------------------------->
const DashboardPage = lazy(() => import("./pages/LawyerPages/DashboardPage"));
const ContactPage = lazy(() =>
  import("./pages/LawyerPages/Contact/ContactPage")
);
const ViewContactPage = lazy(() =>
  import("./pages/LawyerPages/Contact/ViewContactPage")
);
const EditContactPage = lazy(() =>
  import("./pages/LawyerPages/Contact/EditContactPage")
);
const TaskTypePage = lazy(() =>
  import("./pages/LawyerPages/Task/TaskTypePage")
);
const TaskPage = lazy(() => import("./pages/LawyerPages/Task/TaskPage"));

const CasesPage = lazy(() => import("./pages/LawyerPages/Cases/CasesPage"));

const CalendarPage = lazy(() =>
  import("./pages/LawyerPages/Calendar/CalendarPage")
);
const AddContactPage = lazy(() =>
  import("./pages/LawyerPages/Contact/AddContactPage")
);
const ActivitiesPage = lazy(() =>
  import("./pages/LawyerPages/Activities/ActivitiesPage")
);
const BillingPage = lazy(() =>
  import("./pages/LawyerPages/Billing/BillingPage")
);
const AccountPage = lazy(() =>
  import("./pages/LawyerPages/Accounts/AccountPage")
);
const DocumentPage = lazy(() =>
  import("./pages/LawyerPages/Documents/DocumentPage")
);
const Communications = lazy(() =>
  import("./pages/LawyerPages/Communications/CommunicationPage")
);
const ReportPage = lazy(() => import("./pages/LawyerPages/Reports/ReportPage"));

export const routes = [
  {
    path: "/",
    element: <HomePage />,
  },

  {
    path: "/dashboard",
    element: <DashboardPage />,
  },
  {
    path: "/dashboard/calendars",
    element: <CalendarPage />,
  },

  {
    path: "/login",
    element: <SignupPage />,
  },
  {
    path: "/dashboard/contact-details",
    element: <ContactPage />,
  },
  {
    path: "/dashboard/add-contact-details",
    element: <AddContactPage />,
  },
  {
    path: "/dashboard/contact-details/:contactId",
    element: <ViewContactPage />,
  },
  {
    path: "/dashboard/contact-details/edit-contact-details/:contactId",
    element: <EditContactPage />,
  },
  {
    path: "/dashboard/tasks",
    element: <TaskPage />,
  },
  {
    path: "/dashboard/cases",
    element: <CasesPage />,
  },
  {
    path: "/dashboard/activities",
    element: <ActivitiesPage />,
  },
  {
    path: "/dashboard/bills",
    element: <BillingPage />,
  },
  {
    path: "/dashboard/bank_accounts",
    element: <AccountPage />,
  },
  {
    path: "/dashboard/documents",
    element: <DocumentPage />,
  },
  {
    path: "/dashboard/communications",
    element: <Communications />,
  },
  {
    path: "/dashboard/reports",
    element: <ReportPage />,
  },
  {
    path: "/dashboard/settings",
    element: <DocumentPage />,
  },
  {
    path: "/dashboard/custom_fields",
    element: <CustomeFields />,
  },
];
