import { configureStore } from '@reduxjs/toolkit';
import { persistedReducer } from './users/userSlice';
import { persistStore } from 'redux-persist';
import { combineReducers } from 'redux';

const rootReducer = combineReducers({
  user: persistedReducer,
});

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,  // Disables the serializable check
    }),
});

export const persistor = persistStore(store);
export default store;
