import React, { useEffect, useState, useMemo } from "react";
import {
  Route,
  Routes,
  Navigate,
  useNavigate,
  useLocation,
} from "react-router-dom";
import { routes } from "../Routes";
import NotFoundPage from "../pages/NotFoundPage";
import SuspanceLoading from "./SuspanceLoading";
import SignupPage from "../pages/SignupPage";
import HomePage from "../pages/LandingPages/HomePage";

const ProtectedRoutes = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  const isAuthenticated = localStorage.getItem("persist:root");
  const userId = localStorage.getItem("UserId");

  useEffect(() => {
    if (!isAuthenticated || !userId) {
      if (location.pathname !== "/" && location.pathname !== "/login") {
        navigate("/login");
      }
      setLoading(false);
    } else {
      setLoading(false);
    }
  }, [navigate, isAuthenticated, userId, location]);

  const processedRoutes = useMemo(() => {
    return (
      routes
        ?.filter(
          (route) => !route.protected || (route.protected && isAuthenticated)
        )
        .map((route, index) => (
          <Route key={index} path={route.path} element={route.element} />
        )) || null
    );
  }, [isAuthenticated]);

  if (loading) {
    return <SuspanceLoading />;
  }

  return (
    <Routes>
      <Route path="/" element={<HomePage />} />
      <Route path="/login" element={<SignupPage />} />
      {processedRoutes}

      <Route path="*" element={<NotFoundPage />} />

      {!isAuthenticated && (
        <Route path="*" element={<Navigate to="/login" replace />} />
      )}
    </Routes>
  );
};

export default ProtectedRoutes;
