import { Box, Typography } from "@mui/material";
import React from "react";

const NotFoundPage = () => {
  return (
    <div>
      <Box>
        <Typography
          variant="h1"
          color="initial"
          sx={{ fontSize: "6rem", color: "red" }}
        >
          404
        </Typography>
      </Box>
    </div>
  );
};

export default NotFoundPage;
