import React, { useEffect, useState } from "react";
import {
  Box,
  Avatar,
  Menu,
  MenuItem,
  ListItemIcon,
  Divider,
  IconButton,
  Typography,
  Tooltip,
} from "@mui/material";
import { FaSignOutAlt } from "react-icons/fa";
import { MdOutlineSettings } from "react-icons/md";
import { MdOutlineNotificationsActive } from "react-icons/md";
import { MdOutlineMenu } from "react-icons/md";
import { AiOutlineSearch } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import { getFirstCharacters } from "../../../styles/styles";
import { userData } from "../../../static/staticdata";

const Navbar = ({ handleDrawerOpen }) => {
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const [userData, setUserData] = useState(null);
  const [fistChar, setFirstChar] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleSearchChange = (e) => { };


  useEffect(() => {
    const storedUserData = JSON.parse(localStorage.getItem("UserId"));
    if (storedUserData) {
      setUserData(storedUserData);
      setFirstChar(getFirstCharacters(storedUserData.emailId));
    } else {
      setFirstChar(null);
    }
  }, []);

  return (
    <>
      <div className={`w-full bg-[#3957db] p-3`}>
        <div className="800px:h-[12px] 800px:my-[20px] flex items-center justify-between">
          <div className="flex items-center gap-4">
            <MdOutlineMenu
              onClick={handleDrawerOpen}
              size={30}
              className="text-white cursor-pointer font-bold"
            />

            <p
              className="text-white text-2xl font-extrabold pointer-events-auto cursor-pointer"
              onClick={() => navigate("/")}
            >
              Lexmom
            </p>
          </div>
          {/* search box */}
          <div className="w-[50%] relative">
            <input
              type="text"
              placeholder="Search Product..."
              value={searchTerm}
              onChange={handleSearchChange}
              className="h-[50px] w-full px-2 border-[#3957db] border-[2px] rounded-2xl"
            />
            <AiOutlineSearch
              size={30}
              className="absolute right-2 top-2.5 cursor-pointer"
            />
          </div>

          <div className="">
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                textAlign: "center",
              }}
            >
              <Typography
                sx={{ minWidth: 50, display: "flex", justifyContent: "end" }}
              >
                <MdOutlineNotificationsActive
                  size={30}
                  className="text-white"
                />
              </Typography>
              <Typography
                sx={{ minWidth: 50, display: "flex", justifyContent: "end" }}
              >
                <MdOutlineSettings size={30} className="text-white" />
              </Typography>

              <Tooltip title="Account settings">
                <IconButton
                  onClick={handleClick}
                  size="small"
                  sx={{ ml: 2 }}
                  aria-controls={open ? "account-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                >
                  <Avatar sx={{ width: 32, height: 32 }}>{fistChar}</Avatar>
                </IconButton>
              </Tooltip>
            </Box>
            <Menu
              anchorEl={anchorEl}
              id="account-menu"
              open={open}
              onClose={handleClose}
              onClick={handleClose}
              PaperProps={{
                elevation: 0,
                sx: {
                  overflow: "visible",
                  filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                  mt: 1.5,
                  "& .MuiAvatar-root": {
                    width: 32,
                    height: 32,
                    ml: -0.5,
                    mr: 1,
                  },
                  "&::before": {
                    content: '""',
                    display: "block",
                    position: "absolute",
                    top: 0,
                    right: 14,
                    width: 10,
                    height: 10,
                    bgcolor: "background.paper",
                    transform: "translateY(-50%) rotate(45deg)",
                    zIndex: 0,
                  },
                },
              }}
              transformOrigin={{ horizontal: "right", vertical: "top" }}
              anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            >
              <MenuItem onClick={handleClose}>
                <Tooltip title={userData ? userData.emailId : null}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Avatar /> Profile
                  </Box>
                </Tooltip>
              </MenuItem>

              <Divider />

              <MenuItem
                onClick={() => {
                  navigate("/login");
                  handleClose();
                  localStorage.removeItem("UserId");
                }}
              >
                <ListItemIcon>
                  <FaSignOutAlt fontSize="small" />
                </ListItemIcon>
                Logout
              </MenuItem>
            </Menu>
          </div>
        </div>
      </div>
    </>
  );
};

export default Navbar;
